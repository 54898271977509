import { useContext, useEffect, useState } from 'react';
import { Dialog as MuiDialog, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { Done } from '@mui/icons-material';
import { MRT_ColumnDef } from 'material-react-table';

import { Button, Dialog, Skeleton, Table } from '../../../components';
import { DateFormatServer } from '../../../utils/Types';
import { LooseObject } from '../../../utils/Types';
import AllImages from './AllImages';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import {
  getDateFormat,
  getUniqTraits,
  logout,
  postToServer,
  sortArrayByAnotherArray,
} from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectUser } from '../../../redux/reducers/userSlice';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import ImageInfoWithNavi from './ImageInfoWithNavi';

type Props = {
  data: LooseObject;
};

const ListOfImages = ({ data }: Props) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [images, setImages] = useState<LooseObject[]>([]);
  const [tableRows, setTableRows] = useState<LooseObject[]>([]);
  const [openDialogAllImages, setOpenDialogAllImages] = useState(false);
  const [openDialogImageDetails, setOpenDialogImageDetails] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>({});
  const [currentImagesInDataTable, setCurrentImagesInDataTable] = useState<
    LooseObject[]
  >([]);
  const [shouldReloadData, setShouldReloadData] = useState(false);

  const org = useAppSelector(selectOrg);
  const user = useAppSelector(selectUser);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, [data]);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'camera/ListOfImages',
        params: {
          dateFrom: moment(data.id, getDateFormat(org, 'short')).format(
            DateFormatServer.SHORT
          ),
          includeImage: true,
        },
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            if (org?.isUSA) {
              serverData.forEach(i => {
                i.traits?.forEach((t: any) => {
                  t.label = t.label?.replaceAll('AUS', 'BMS');
                });
                // if (auth?.orgOwner === 'jbsomaha') {
                //   i.traits = i.traits?.filter(
                //     (t: any) => t.key !== 'msa_marbling'
                //   );
                // }
              });
            }

            setImages(serverData);
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const allTraits = images.map(i =>
    i.traits.map((j: any) => ({ key: j.key, label: j.label }))
  );

  const uniqTraits = getUniqTraits(allTraits);

  const columns: MRT_ColumnDef<LooseObject, any>[] = [
    { accessorKey: 'barcode', header: 'Barcode' },
  ];

  uniqTraits.forEach(i => {
    columns.push({
      accessorKey: i.key,
      header: i.label,
      muiTableBodyCellProps: ({ row }) => ({
        onClick: () => {
          const sortedImages = sortArrayByAnotherArray(
            images,
            'barcode',
            tableRows.map(i => i.barcode)
          );

          setCurrentImagesInDataTable(sortedImages);
          setCurrentItem(row.original);
          setOpenDialogImageDetails(true);
        },
      }),
    });
  });

  useEffect(() => {
    const rows: LooseObject[] = [];

    images.forEach(item => {
      const row = {
        ...item,
        barcode:
          user?.isDataMaintainer && item.isUpdatedByDataMaintainer ? (
            <Stack direction="row" alignItems="center">
              {item.barcode}
              <Done sx={{ width: 15, height: 15, color: 'primary.light' }} />
            </Stack>
          ) : (
            item.barcode
          ),
      };
      uniqTraits.forEach(i => {
        row[i.key] = item.traits.find((j: any) => j.key === i.key)?.value;
      });

      rows.push(row);
    });
    setTableRows(rows);
  }, [images]);

  return (
    <Stack px={2} pb={2} bgcolor={grey[300]}>
      {images.length > 0 ? (
        <Table
          renderTopToolbarCustomActions={() => (
            <Stack direction="row">
              <Typography variant="h6">{`List of Images for ${data.id}`}</Typography>
              <Stack ml={2}>
                <Button
                  title="Show All Images"
                  size="small"
                  onClick={() => setOpenDialogAllImages(true)}
                />
              </Stack>
            </Stack>
          )}
          columns={columns}
          data={tableRows}
          enableColumnActions={false}
          enableHiding={false}
          enableColumnResizing
          enableDensityToggle={false}
          enableBottomToolbar={false}
          enablePagination={false}
          enableStickyHeader
          muiTableContainerProps={{ sx: { maxHeight: '430px' } }}
          enableRowNumbers
          rowNumberDisplayMode="static"
          // globalFilterFn="contains"
          state={{ isLoading: loading }}
          initialState={{ density: 'compact' }}
          layoutMode="grid"
          defaultColumn={{
            minSize: 20,
            maxSize: 1000,
            size: 150,
          }}
        />
      ) : (
        <Skeleton minHeight={100} bgcolor="white" />
      )}
      <MuiDialog
        open={openDialogAllImages}
        maxWidth="xl"
        onClose={() => {
          setOpenDialogAllImages(false);
          if (shouldReloadData) {
            setShouldReloadData(false);
            fetchData();
          }
        }}
      >
        <AllImages
          user={user}
          org={org}
          onCancel={() => {
            setOpenDialogAllImages(false);
            if (shouldReloadData) {
              setShouldReloadData(false);
              fetchData();
            }
          }}
          date={data.id}
          data={images}
          loadData={fetchData}
          shouldReloadListOfImages={(v: boolean) => setShouldReloadData(v)}
        />
      </MuiDialog>
      <Dialog
        open={openDialogImageDetails}
        onClose={() => {
          setOpenDialogImageDetails(false);
          setCurrentItem({});
          if (shouldReloadData) {
            setShouldReloadData(false);
            fetchData();
          }
        }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: 'rgba(0,0,0,0.5)',
        }}
        fullScreen
        isTransparent
      >
        <ImageInfoWithNavi
          user={user}
          org={org}
          onClose={() => {
            setOpenDialogImageDetails(false);
            setCurrentItem({});
            if (shouldReloadData) {
              fetchData();
            }
          }}
          initialImage={currentItem}
          allImages={currentImagesInDataTable}
          isImageUrlProvided
        />
      </Dialog>
    </Stack>
  );
};

export default ListOfImages;
