import {
  AutoAwesome,
  BedroomBaby,
  HowToVote,
  Business,
  FilterVintage,
  Piano,
  CameraAltOutlined,
  SettingsInputComponent,
  Apartment,
  GridView,
  ImageAspectRatioOutlined,
  // Security,
  BallotOutlined,
  ViewTimelineOutlined,
  ViewWeekOutlined,
  Insights,
  Leaderboard,
  CastForEducation,
  CameraEnhanceOutlined,
  Share,
  AcUnit,
  PolicyOutlined,
  PeopleOutline,
  Fence,
  Balcony,
  Camera,
  Flip,
} from '@mui/icons-material';
import type { FeatureCollection, Point } from 'geojson';

import {
  Login,
  NotFound,
  Loading,
  ForgotPassword,
  ResetPassword,
  UpdateProfile,
  UpdatePassword,
  DataView,
  SummaryOfImages,
  OrgProfile,
  UserList,
  OrgList,
  PluginList,
  // ListOfLots,
  CameraOverview,
  // InsightsDemo,
  // AdvancedSecurity,
  Reset2FA,
  DepthCamera,
  // SharedInsights,
  InsightsDemo2,
  DataSources,
  SharedInsights2,
  Terms,
  AllUsers,
  FeedyardOverview,
  Yards,
  ScanView,
  ProducerOverview,
  FeedlotOverview,
  FeedlotListOfLots,
  BeefMSAOverview,
  SheepProbeOverview,
  SheepProbeSummaryOfData,
  LoginV2,
} from '../pages';
import {
  NavItem,
  PluginName,
  Message,
  DataSource,
  InsightItemType,
  ChartType,
  LabelValuePair,
  DataSourceKey,
} from './Types';
import CalendarClockIcon from '../assets/icons/calendar-clock.png';
import BuildingIcon from '../assets/icons/building.png';
import ListCheckIcon from '../assets/icons/list-check.png';
import UserEditIcon from '../assets/icons/user-edit.png';
import UserKeyIcon from '../assets/icons/user-key.png';

export const PROJECT_TITLE = 'MEQ Insights';
export const COPY_RIGHT = `Copyright © ${PROJECT_TITLE} 2023.`;

export const CURRENT_FRONTEND_VERSION = 202402081518;

export const SUCCESS_CODE = 700;

export const ORGS_HIDE_USERS_FROM_EACH_OTHER = [
  'meq-insights-demo-aus',
  'meq-insights-demo-usa',
];

export const DEMO_ORGS = ['ausdemocallan', 'usademojw'];

export const UPPER_CASE_TERMS = [
  'IMF',
  'AUS',
  'MSA',
  'PIC',
  'GLQ',
  'RFID',
  'MEQ',
  'HDW',
];

export const DATA_POOL_ITEM_EXPIRED_IN_MILLISECONDS = 60 * 60 * 1000; // 60 minutes

export const VIDEO_SAS_TOKEN =
  '?sp=r&st=2023-11-11T19:04:10Z&se=2027-04-23T02:04:10Z&spr=https&sv=2022-11-02&sr=c&sig=MxDquXoj8vFvGI8bYuzCqzWaXL5CNuyBJDFmhS7R06Q%3D';
export const WEBCAM_SAS_TOKEN =
  '?sp=r&st=2023-11-27T02:56:45Z&se=2026-07-22T11:56:45Z&spr=https&sv=2022-11-02&sr=c&sig=Zc8wOt5uTOaItII9%2BcN5Clbwzl0%2F0hkzs6F6WS2cXDY%3D';

export const QUERY_DATA_SOURCE_ENDPOINT = 'GetDataSource';

export const AVAILABEL_DATA_SOURCE: DataSource[] = [
  {
    key: DataSourceKey.SUMMARY_OF_IMAGES,
    label: 'Summary Of Images',
    endpoint: 'camera/SummaryImages',
    isNoParamsPermitted: true,
    excludeAttributes: [],
  },
  {
    key: DataSourceKey.LIST_OF_IMAGES,
    label: 'List Of Images',
    endpoint: 'camera/ListOfImages',
    excludeAttributes: ['barcode', 'photo_date', 'photo_url'],
  },
];

export const HISTOGRAM_Y_AXIS_TITLE = 'Count';
export const HISTOGRAM_Y_AXIS_TITLE_PERCENT = 'Percent';

export const InsightItemTypes: LabelValuePair[] = [
  {
    label: 'Chart',
    value: InsightItemType.CHART,
  },
  {
    label: 'Date Table',
    value: InsightItemType.DATA_TABLE,
  },
];

export const ChartTypes: LabelValuePair[] = [
  {
    label: 'Bar Chart',
    value: ChartType.BAR_CHART,
  },
  {
    label: 'Donut Chart',
    value: ChartType.DONUT_CHART,
  },
  {
    label: 'Histogram',
    value: ChartType.HISTOGRAM,
  },
  {
    label: 'Line Chart',
    value: ChartType.LINE_CHART,
  },
  {
    label: 'Pie Chart',
    value: ChartType.PIE_CHART,
  },
  {
    label: 'Scatter Plot',
    value: ChartType.SCATTER_PLOT,
  },
  {
    label: 'Violin Plot',
    value: ChartType.VIOLIN_PLOT,
  },
];

export const DEFAULT_MESSAGE: Message = {
  text: null,
  type: 'error',
} as const;

export const PLUGINS = [
  {
    name: PluginName.BEEF_MSA,
    icon: <BedroomBaby />,
  },
  {
    name: PluginName.BREEDING,
    icon: <BedroomBaby />,
  },
  {
    name: PluginName.CAMERA,
    icon: <CameraAltOutlined />,
  },
  {
    name: PluginName.FEEDLOT,
    icon: <HowToVote />,
  },
  {
    name: PluginName.FEEDYARD,
    icon: <Balcony />,
  },
  {
    name: PluginName.GENETICS,
    icon: <AutoAwesome />,
  },
  {
    name: PluginName.INSIGHTS,
    icon: <Insights />,
  },
  {
    name: PluginName.LIVE_INTERNAL,
    icon: <Camera />,
  },
  {
    name: PluginName.MACHINE_LEARNING,
    icon: <CastForEducation />,
  },
  {
    name: PluginName.PROCESSING,
    icon: <FilterVintage />,
  },
  {
    name: PluginName.PRODUCER,
    icon: <Business />,
  },
  {
    name: PluginName.SCHEDULING,
    icon: <img src={CalendarClockIcon} alt="icon" />,
  },
  {
    name: PluginName.SHEEP_PROBE,
    icon: <FilterVintage />,
  },
  {
    name: PluginName.WHOLESALE,
    icon: <Piano />,
  },
];

export const NAV_ITEMS: NavItem[] = [
  {
    title: 'Overview',
    to: '/feedyard/overview',
    page: <FeedyardOverview />,
    category: PluginName.FEEDYARD,
    icon: <BallotOutlined sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Yards',
    to: '/feedyard/yards',
    page: <Yards />,
    category: PluginName.FEEDYARD,
    icon: <Fence sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Overview',
    to: '/feedlot/overview',
    page: <FeedlotOverview />,
    category: PluginName.FEEDLOT,
    icon: <BallotOutlined sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Yards',
    to: '/feedlot/list-of-lots',
    page: <FeedlotListOfLots />,
    category: PluginName.FEEDLOT,
    icon: <Fence sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Data View',
    to: '/scheduling/data-view',
    page: <DataView />,
    category: PluginName.SCHEDULING,
    contentPadding: 0,
    icon: <ViewTimelineOutlined sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Overview',
    to: '/camera/overview',
    page: <CameraOverview />,
    category: PluginName.CAMERA,
    icon: <GridView sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Summary Of Images',
    to: '/camera/summary-of-images',
    page: <SummaryOfImages />,
    category: PluginName.CAMERA,
    icon: <ImageAspectRatioOutlined sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Overview',
    to: '/sheep-probe/overview',
    page: <SheepProbeOverview />,
    category: PluginName.SHEEP_PROBE,
    icon: <GridView sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Summary Of Data',
    to: '/sheep-probe/summary-of-data',
    page: <SheepProbeSummaryOfData />,
    category: PluginName.SHEEP_PROBE,
    icon: <ImageAspectRatioOutlined sx={{ color: 'grey.200' }} />,
  },
  // {
  //   title: 'Demo',
  //   to: '/insights/demo',
  //   page: <InsightsDemo />,
  //   category: PluginName.INSIGHTS,
  //   icon: <Leaderboard sx={{ color: 'grey.200' }} />,
  // },
  // {
  //   title: 'Shared',
  //   to: '/insights/shared',
  //   page: <SharedInsights />,
  //   category: PluginName.INSIGHTS,
  //   icon: <Share sx={{ color: 'grey.200' }} />,
  // },
  {
    title: 'Demo',
    to: '/insights/demo2',
    page: <InsightsDemo2 />,
    category: PluginName.INSIGHTS,
    icon: <Leaderboard sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Shared',
    to: '/insights/shared2',
    page: <SharedInsights2 />,
    category: PluginName.INSIGHTS,
    icon: <Share sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Data Sources',
    to: '/insights/data-sources',
    page: <DataSources />,
    category: PluginName.INSIGHTS,
    icon: <AcUnit sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Depth Camera',
    to: '/machine-learning/depth-camera',
    page: <DepthCamera />,
    category: PluginName.MACHINE_LEARNING,
    icon: <CameraEnhanceOutlined sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Scan View',
    to: '/live-internal/scan-view',
    page: <ScanView />,
    category: PluginName.LIVE_INTERNAL,
    icon: <Flip sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Map',
    to: '/producer/overview',
    page: <ProducerOverview />,
    category: PluginName.PRODUCER,
    icon: <ViewWeekOutlined sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Overview',
    to: '/beefmsa/overview',
    page: <BeefMSAOverview />,
    category: PluginName.BEEF_MSA,
    icon: <ViewWeekOutlined sx={{ color: 'grey.200' }} />,
  },
  // {
  //   title: 'List Of Lots',
  //   to: '/producer/list-of-lots',
  //   page: <ListOfLots />,
  //   category: PluginName.PRODUCER,
  //   icon: <ViewWeekOutlined sx={{ color: 'grey.200' }} />,
  // },
  {
    title: 'Update Profile',
    to: '/settings/update-profile',
    page: <UpdateProfile />,
    category: 'Account',
    icon: <img src={UserEditIcon} alt="icon" />,
  },
  {
    title: 'Update Password',
    to: '/settings/update-password',
    page: <UpdatePassword />,
    category: 'Account',
    icon: <img src={UserKeyIcon} alt="icon" />,
  },
  // {
  //   title: 'Advanced Security',
  //   to: '/settings/advanced-security',
  //   page: <AdvancedSecurity />,
  //   category: 'Account',
  //   icon: <Security sx={{ color: 'grey.200' }} />,
  // },
  {
    title: 'Organisation Profile',
    to: '/management/organisation-profile',
    page: <OrgProfile />,
    category: 'Management',
    icon: <img src={BuildingIcon} alt="icon" />,
  },
  {
    title: 'User List',
    to: '/management/user-list',
    page: <UserList />,
    category: 'Management',
    icon: <img src={ListCheckIcon} alt="icon" />,
  },
  {
    title: 'Orgnisation List',
    to: '/administration/org-list',
    page: <OrgList />,
    category: 'Administration',
    icon: <Apartment sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Plugin List',
    to: '/administration/plugin-list',
    page: <PluginList />,
    category: 'Administration',
    icon: <SettingsInputComponent sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'All Users',
    to: '/administration/all-users',
    page: <AllUsers />,
    category: 'Administration',
    icon: <PeopleOutline sx={{ color: 'grey.200' }} />,
  },
  {
    title: 'Terms and Conditions',
    to: '/administration/terms-and-conditions',
    page: <Terms />,
    category: 'Administration',
    icon: <PolicyOutlined sx={{ color: 'grey.200' }} />,
  },
];

export const OTHER_PAGES: NavItem[] = [
  {
    title: 'Not Found',
    to: '*',
    page: <NotFound />,
    hideNav: true,
    hideFooter: true,
    isPublic: true,
  },
  {
    title: 'Login',
    to: '/',
    page: <Login />,
    hideNav: true,
    hideFooter: true,
    isPublic: true,
  },
  {
    title: 'Forgot Password',
    to: '/forgot-password',
    page: <ForgotPassword />,
    hideNav: true,
    hideFooter: true,
    isPublic: true,
  },
  {
    title: 'Reset Password',
    to: '/reset-password',
    page: <ResetPassword />,
    hideNav: true,
    hideFooter: true,
    isPublic: true,
  },
  {
    title: 'Reset 2FA',
    to: '/reset-2fa',
    page: <Reset2FA />,
    hideNav: true,
    hideFooter: true,
    isPublic: true,
  },
  {
    title: 'Loading',
    to: '/loading',
    page: <Loading />,
    hideNav: true,
    hideFooter: true,
    isPublic: true,
  },
  {
    title: 'Loading',
    to: '/login-v2',
    page: <LoginV2 />,
    hideNav: true,
    hideFooter: true,
    isPublic: true,
  },
];

export const CONTENT_PADDING = 3;
export const CHART_BACKGROUND_COLOR = '#EEEEEE';

export const MONTHS = [
  { label: 'Jan', value: 0 },
  { label: 'Feb', value: 1 },
  { label: 'Mar', value: 2 },
  { label: 'Apr', value: 3 },
  { label: 'May', value: 4 },
  { label: 'Jun', value: 5 },
  { label: 'Jul', value: 6 },
  { label: 'Aug', value: 7 },
  { label: 'Sept', value: 8 },
  { label: 'Oct', value: 9 },
  { label: 'Nov', value: 10 },
  { label: 'Dec', value: 11 },
];

export const MAP_STYLE = 'mapbox://styles/mapbox/outdoors-v9';

export const EMPTY_FEATURE_COLLECTION: FeatureCollection<Point> = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [144.9631, -37.8136], //Melbourne
      },
      properties: {},
    },
  ],
};

export const RSA_PUBLIC_KEY = `-----BEGIN PGP PUBLIC KEY BLOCK-----

xsFNBGSBU8IBEADyB1SZFlwmiTRl9vwBm4h59bKDrdNQJg0AZvId1XVL/zvN
9/6d/Xas6MpIXBoqRkCeSSsM3THOO6H20XKygcDD9fi3B6xj8dpl5FjeL7OB
GGdp+reG0BQijzLHYeG90MIpGS4xQiM04cjIi0+Cw37EEl6BdC8vTiQOo4Xq
Gxaah7zFUNej8K1k7iUR1bLsyZD3FgOetZ1clNxtVMmenz+vlSwgatN1rRce
cRwJpngBXv88cknmXRmqoBgT+o3ZpDnvpBBMzu2wqifvnSxpGVZ3yw9N6esc
8PzsBAfQJyZMlDU7o7JjgM0XquYbcDpmIXfK5JzVFML9cW7HVgbvAVzhc1TU
yioyM8zP+r6VGZFLfH7V62/srisX45fsQVl1LARzl1N6GXrw8vdv5QZsBy68
3OVufSPyDm3eMetkcNxLxa3K4Mq195gW2zsftYUElmdWI65qLLZwDB1fY11a
zzdvRhZsbO+mu19SJEQWMCldITVsK1rJMOW7sEok1cX8rilNm6g0rQidXAsb
d4GTBTzuLjPQj7m+S+5CUcyNCBpxbg/h03Djjk/bCew7PQXMTv2XZ5fXx+OL
q2vM7yo1Bgp4yjGyiGPd2Vp9l3/4GBa1KMK4BVBJAGh7Rmp0sOoJepWz8Kgy
FDXNijpraWKHjvrRb9T2wICuaJa6C5n2GZIn/QARAQABzQxNRVEgSW5zaWdo
dHPCwYoEEAEIAD4FgmSBU8IECwkHCAmQvYxwTcUm+YgDFQgKBBYAAgECGQEC
mwMCHgEWIQQiUoFcBkJ6qn6UaMK9jHBNxSb5iAAAMz4P/RmG3cIg0rMiMiMC
73gLaMLvehmznWgXGbyMOnA8kZbJAL+B1CV2za8IDjDzG4a4E3S5TUX1Rl9d
tKIGlXm0StkP1edDEa3EP+7utrc+2Sw4OWsqtdTm1qqoRW3XsiBTSrDJfLKH
6BLPnCypsYpHFyJXwnMqgIBHQHoLaaVh1yPzown9aT/RPb2cB1zeAKsPcwOD
Y72xTxHF70R362jCg7IZPdHHuu75gsKG5hu422My1dnEh3X87+sLTz3Si6qn
9tWESRmuh1X9wuPIR/5LeMtTzUG88Mi0XT02hDbRteC7N00ayzwqOumSmvM3
MWE8HYnAa72YEJ/xeILjVD2oKI7hv5AWHjrOTutmKQ5ghDzokkBGSzQiiuyt
xmZO9UA3us1swjCQYTHt0duMG4bMU2Ut/tClq/INMY20ryoKLCbTl8Nb/7V2
Se4Em8h/EYc8Y9oYRcuLHXy0+cM8uhnKetPbrC2cIJkc1zXmmH5xXkBsbOqy
6rLJvvgq1+v7EQeQsTE74Ub9lvQB3vP0K0mnqzWXZvmwwshjg4fSYxR8a/Fb
C/LdjQL2Soab1izkYgzbDPPYmUr97O9MjXcrlq6XMEssgh89irYAxze5JSrF
YuB+EWMqjKv7cY/BjzOtVehiMawW02MguFlwX+R2qOEDfWgez9+namGLhvLl
NJAJp736zsFNBGSBU8IBEADBYNc0SWIOGFiIntaiUhwCnkA65YzSLQMpWslH
gpO1YDff9mkNMvDQ+/YsCvAtDGIeRolV+m78CG+hRpb5ADdE3ik0Udcl13cn
H32rvN3uGakiglb5piqdgTsyyLFgnHKwYNhePYZptvYzeRltRIRyjt6pGhpy
nzmBifIfEfqYRSdJ48NxKWIsfx6tLE6UrGks663BjO/UCjvEoi2QHAK4YXIv
bQGjGf80ogEmgKIXn6fgL6Vh22Xx+jC+QFRQ3LZLKjeMMrzgJnr8YBzVXr0A
WXmMw1w3mzYD6vErS/iyZLp1au1AhIJML0Reo4NPHHDgtZNQupUiJu7fMXNs
flj9MBipQUhd4tLNDvxaV2BTZ+jqihueRtCsPH2ofF3myUkBvqZKf0aKzSDx
V0lR8g/TZ9wNT5JZQ6GhbMvEMHiilLg6uxhZvf575zp8EbWncqJEyYzkAKHy
cf+VK3L2pyY7EcFyJ91k5Juzwtpnxmlqhsd70ewN0ni+jhge91ubHmMhDAmG
m3VmztdMIOQpPe/OA2K1xRpTsfhJ6y3Bo/CngfqU/sv06slIRlwXCikUcupC
LX1XIp20WcP1bqyBfmdEACywM/jsH44EvAZV0IXi2rCtBaoMrgB/24HjjZfP
EXizbyLR6SSLz0ag9Cc0trrKU+DODtuNbir3RiZRwmPM2wARAQABwsF2BBgB
CAAqBYJkgVPCCZC9jHBNxSb5iAKbDBYhBCJSgVwGQnqqfpRowr2McE3FJvmI
AACcgg/9FAEoGdHtosBjh20L1aOBWJSzld2oWy27XumzFMXZDI6C2LHCb712
LNiaX/e0ozgnRKtxnD81YOpJXpcfBhAUP5Ek6IkwZAsIbmM2s8CKB/oec23Q
cBqLTyvX6pUfAu3nAVzVJwXzWgXXV5nZBZXb/NJ3Yb9yRWy2zZugoVM0RsTx
o/aDp7tiBO68ej/ZH6cHMtuyfNpRqufPLmFcwzpdtgFa7zs31mB71NLqan+l
U155k2EBPfyCVtLNp3AhvDB08vvnxNSe2eyau0TpKunopZdWTvbxPQue/DZC
o1oCh+022fZRCN9lc56Fp0xkjK1PDgcLFGx6OhNsnjxntZWtzWAqlv0bYKFJ
w5ZW7ASunO97IszGsWSMummPgqYkG3PJp5qYEHxBBNa/Je9GEsJlJYfkwyrB
Slhvz/Fb5cBVjbRfByi5GGzV/SCP2I7de8hKng/gt5vJ8HhIfnTJ1e5b31oL
w4xPNKKb/6E6cq2tt1ms3uZQTa9ImHhx85CghwUThuOY8aHww0T0VUVf9woi
QtZ4vXnaMHKNXTj4kUNSaXZQ0zLOwxLjNIyAsEGWexwLOA1Yn/CbkaABXywC
fW3xTiFNyyGPvMUtTvJHcCDT0cqSTg12cY6nvFL2kUJQ5uUIg00yqnJDTil6
yxEpYBEqAAVRRKdtuHl3j5yQKV8H8lM=
=5A7p
-----END PGP PUBLIC KEY BLOCK-----`;
