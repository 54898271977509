import { useContext, useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Popover,
} from '@mui/material';
import moment, { Moment } from 'moment';
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
  Auth,
  ChartPoint,
  ChartType,
  DateFormatServer,
  LooseObject,
  Org,
} from '../../../utils/Types';
import { Chart, Dialog } from '../../../components';
import {
  assignIds,
  getDateFormat,
  getUniqTraits,
  postToServer,
} from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import ImageInfo from '../SummaryOfImages/ImageInfo';

const TraitsScatter = ({
  data,
  loading,
  auth,
  org,
}: {
  data: LooseObject[];
  loading: boolean;
  auth: Auth;
  org: Org;
}) => {
  const snackbar = useContext(SnackbarContext);
  const [loadingImages, setLoadingImages] = useState(false);

  const allDateStrings = data
    ?.map(i => i.photo_date)
    ?.sort(
      (a: string, b: string) =>
        moment(b, DateFormatServer.SHORT).valueOf() -
        moment(a, DateFormatServer.SHORT).valueOf()
    );

  const [uniqTraits, setUniqTraits] = useState<LooseObject[]>([]);
  const [traitX, setTraitX] = useState<LooseObject | undefined>();
  const [traitY, setTraitY] = useState<LooseObject | undefined>();

  const [date, setDate] = useState<Moment | null>(moment());
  const [images, setImages] = useState<LooseObject[]>([]);

  const [currentItem, setCurrentItem] = useState<LooseObject>({});
  const [openDialog, setOpenDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    let defaultDate = moment();

    if (allDateStrings?.[0]) {
      defaultDate = moment(allDateStrings?.[0], DateFormatServer.SHORT);
      setDate(defaultDate);

      loadImages(defaultDate);
    }
  }, [data]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loadImages = (forDate: Moment | null) => {
    if (forDate && auth?.token) {
      setLoadingImages(true);
      postToServer({
        action: 'camera/ListOfImages',
        params: {
          dateFrom: forDate.format(DateFormatServer.SHORT),
          includeImage: true,
        },
        token: auth.token,
      }).then(response => {
        setLoadingImages(false);
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            if (org?.isUSA) {
              serverData.forEach(i => {
                i.traits?.forEach((t: any) => {
                  t.label = t.label?.replaceAll('AUS', 'BMS');
                });
                // if (auth?.orgOwner === 'jbsomaha') {
                //   i.traits = i.traits?.filter(
                //     (t: any) => t.key !== 'msa_marbling'
                //   );
                // }
              });
            }

            const imagesFromServer: LooseObject[] = assignIds(serverData);

            if (imagesFromServer?.length > 0) {
              setImages(imagesFromServer);

              const allTraits = imagesFromServer.map(i =>
                i.traits
                  .filter((t: any) => t.value !== null)
                  .map((j: any) => ({ key: j.key, label: j.label }))
              );

              const uniq = getUniqTraits(allTraits);

              if (uniq?.length > 1) {
                setUniqTraits(uniq);
                setTraitX(uniq[0]);
                setTraitY(uniq[1]);
              } else {
                setTraitX(undefined);
                setTraitY(undefined);
              }
            }
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const disableDates = (date: Moment) => {
    const currentDateString = date.format(DateFormatServer.SHORT);
    const shouldDisable = !allDateStrings?.includes(currentDateString);
    return shouldDisable;
  };

  const handleChangeTraitX = (event: SelectChangeEvent) => {
    if (event?.target?.value) {
      setTraitX(uniqTraits.find(i => i.key === event.target.value));
    }
  };

  const handleChangeTraitY = (event: SelectChangeEvent) => {
    if (event?.target?.value) {
      setTraitY(uniqTraits.find(i => i.key === event.target.value));
    }
  };

  const handleClickPoint = async ({ x, y }: ChartPoint) => {
    const item = images.find(
      i =>
        (i.traits?.find((j: any) => j.key === traitX?.key)?.value === x ||
          i.traits?.find((j: any) => j.key === traitX?.key)?.value ===
            x?.toString()) &&
        (i.traits?.find((j: any) => j.key === traitY?.key)?.value === y ||
          i.traits?.find((j: any) => j.key === traitY?.key)?.value ===
            y?.toString())
    );
    if (item) {
      setCurrentItem(item);
      setOpenDialog(true);
    }
  };

  return (
    <Chart
      type={ChartType.SCATTER_PLOT}
      title={
        traitX && traitY
          ? traitX?.label + ' VS ' + traitY?.label
          : 'Trait Scatter Plot Chart'
      }
      headerBeforeLoading={
        <Stack direction="row" alignItems="center">
          <Typography variant="body2">{`Show data for ${date?.format(
            getDateFormat(org, 'short')
          )}`}</Typography>
          <Stack>
            <Typography
              onClick={handleClick}
              variant="body2"
              sx={{
                textDecoration: 'underline',
                m: 1,
                ':hover': { color: 'grey.700', cursor: 'pointer' },
              }}
            >
              Change Date
            </Typography>
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateCalendar
                  disableFuture
                  value={date}
                  onChange={(v: Moment | null) => {
                    setDate(v);
                    loadImages(v);
                  }}
                  shouldDisableDate={disableDates}
                  slots={{
                    day: ({ day, ...rest }) => (
                      <PickersDay
                        day={day}
                        {...rest}
                        selected={
                          day.format(getDateFormat(org, 'short')) ===
                          date?.format(getDateFormat(org, 'short'))
                        }
                        sx={{
                          border: allDateStrings.includes(
                            day.format(DateFormatServer.SHORT)
                          )
                            ? 1
                            : 0,
                          borderColor: theme => theme.palette.primary.main,
                        }}
                      />
                    ),
                  }}
                />
              </LocalizationProvider>
            </Popover>
          </Stack>
        </Stack>
      }
      header={
        <Stack
          direction="row"
          spacing={1}
          mt={1}
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          <FormControl size="small" sx={{ bgcolor: 'white' }}>
            <InputLabel>X axis</InputLabel>
            <Select
              value={traitX?.key || ''}
              label="X axis"
              onChange={handleChangeTraitX}
            >
              {uniqTraits?.map(i => (
                <MenuItem
                  key={'x_' + i.key}
                  value={i.key || ''}
                  disabled={i.key === traitY?.key}
                >
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ bgcolor: 'white' }}>
            <InputLabel>Y axis</InputLabel>
            <Select
              value={traitY?.key || ''}
              label="Y axis"
              onChange={handleChangeTraitY}
            >
              {uniqTraits?.map(i => (
                <MenuItem
                  key={'y_' + i.key}
                  value={i.key || ''}
                  disabled={i.key === traitX?.key}
                >
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      }
      data={{
        xTitle: traitX?.label,
        yTitle: traitY?.label,
        data: [
          {
            x: images?.map(
              i => i.traits?.find((j: any) => j.key === traitX?.key)?.value
            ),
            y: images?.map(
              i => i.traits?.find((j: any) => j.key === traitY?.key)?.value
            ),
          },
        ],
      }}
      loading={loading || loadingImages}
      backdrop={
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'rgba(0,0,0,0.5)',
          }}
          fullScreen
          isTransparent
        >
          <ImageInfo
            org={org}
            onClose={() => {
              setOpenDialog(false);
            }}
            data={currentItem}
          />
        </Dialog>
      }
      handleClick={handleClickPoint}
    />
  );
};

export default TraitsScatter;
