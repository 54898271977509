import { useContext, useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import { Dialog, DialogWrapperInfo, Table } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import {
  getDateFormat,
  isNotEmpty,
  logout,
  optimiseQueryResult,
  postToServer,
} from '../../../utils/Helper';
import {
  LooseObject,
  DateFormatServer,
  QueryResultColumn,
} from '../../../utils/Types';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import LocationSwitch from './LocationSwitch';
import LotsInsights from './LotsInsights';
import LotDetails from './LotDetails';
import UpcomingEventList from './UpcomingEventList';

export const MISHIMA_GRADES = ['ULTRA', '5_STAR', '4_STAR', 'CHOICE', 'WAGYU'];

type ServerData = {
  lots: LooseObject[];
  columns: QueryResultColumn[];
  averageUSDAScoreCompanyPast: number | undefined;
  averageDailyIncreaseInUSDAScoreCompanyOverall: number | undefined;
};

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [lotsWithLatestScan, setLotsWithLatestScan] = useState<ServerData>({
    lots: [],
    columns: [],
    averageUSDAScoreCompanyPast: undefined,
    averageDailyIncreaseInUSDAScoreCompanyOverall: undefined,
  });
  const [yard, setYard] = useState<string>('Mesquite');
  const [tableRows, setTableRows] = useState<LooseObject[]>([]);
  const [openDialogInsights, setOpenDialogInsights] = useState(false);
  const [openDialogLotDetails, setOpenDialogLotDetails] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>();

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, [yard]);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'feedyard/GetLotsWithLatestScanAndShipments',
        params: {},
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (
            response.message.type === 'success' &&
            response.serverData &&
            response.serverData
          ) {
            const serverData = response.serverData as ServerData;
            setLotsWithLatestScan(serverData);
          } else {
            snackbar.open(response.message);
          }
          setLoading(false);
        }
      });
    }
  };

  const columns: any[] = [
    { accessorKey: 'LOT', header: 'Lot' },
    { accessorKey: 'HEADCOUNT_YARDSHEET', header: 'Headcount Yardsheet' },
    { accessorKey: 'AVG_MONTHS_OLD', header: 'Months Old' },
    { accessorKey: 'AVG_DAYS_ON_FEED', header: 'Days On Feed' },
    { accessorKey: 'AVG_WEIGHT', header: 'Avg Weight' },
    { accessorKey: 'AVG_USDA_SCORE', header: 'Avg USDA Score' },
    {
      accessorKey: 'SHIPPING_DATE',
      header: 'Est Shipping Date',
      sortingFn: 'dateOnly',
    },
  ];

  if (auth?.orgOwner === 'mishima') {
    columns.splice(6, 0, { accessorKey: 'GRADE_MODE', header: 'Mode Grade' });
  }

  const findGradeMode = (row: LooseObject) => {
    const grades: string[] = [];

    const maxCount = Math.max(
      ...MISHIMA_GRADES.map(k => row['COUNT_OF_' + k] || 0)
    );

    if (maxCount > 0) {
      MISHIMA_GRADES.forEach(k => {
        if (row['COUNT_OF_' + k] === maxCount) {
          grades.push(_.startCase(k.toLowerCase()));
        }
      });
    }
    return grades.join(', ');
  };

  useEffect(() => {
    const rows = lotsWithLatestScan.lots
      .filter(i => i.FEEDLOT === yard)
      .map(i => ({
        ...i,
        AVG_MONTHS_OLD: isNotEmpty(i.AVG_MONTHS_OLD)
          ? Number.parseFloat(i.AVG_MONTHS_OLD.toFixed(2))
          : null,
        GRADE_MODE: findGradeMode(i),
        SHIPPING_DATE: i.SHIPPING_DATE
          ? moment(i.SHIPPING_DATE, DateFormatServer.SHORT).format(
              getDateFormat(org, 'short')
            )
          : null,
      }));
    setTableRows(rows || []);
  }, [lotsWithLatestScan]);

  const lotsShippingThisWeek =
    lotsWithLatestScan.lots.filter(
      i =>
        i.FEEDLOT === yard &&
        i.SHIPPING_DATE &&
        moment(i.SHIPPING_DATE, DateFormatServer.SHORT) <=
          moment().endOf('week') &&
        moment(i.SHIPPING_DATE, DateFormatServer.SHORT) >=
          moment().startOf('week')
    ) || []; // midnight of this Saturday

  const lotsScanningThisWeek: LooseObject[] = [];

  return (
    <Grid container mt={2}>
      <Grid item container xs={12} lg={9} xl={10}>
        <Grid item xs={12}>
          <LocationSwitch
            onChange={setYard}
            onClickShowInsight={() => setOpenDialogInsights(true)}
            auth={auth}
          />
        </Grid>
        <Grid item xs={12} pr={{ xs: 0, lg: 3 }} mt={{ xs: 2, lg: 3 }}>
          <Table
            title="List Of Lots"
            columns={columns}
            columnDataTypes={optimiseQueryResult(lotsWithLatestScan).columns}
            data={tableRows}
            state={{ isLoading: loading }}
            enableColumnActions={false}
            enableBottomToolbar={false}
            enablePagination={false}
            muiTableContainerProps={{ sx: { maxHeight: '780px' } }}
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => {
                setCurrentItem(row.original);
                setOpenDialogLotDetails(true);
              },
            })}
            initialState={{ density: 'compact' }}
          />
          <Dialog
            open={openDialogInsights}
            onClose={() => setOpenDialogInsights(false)}
            isTransparent
          >
            <DialogWrapperInfo
              onClose={() => setOpenDialogInsights(false)}
              maxHeight="100vh"
            >
              <LotsInsights
                lots={
                  lotsWithLatestScan.lots.filter(
                    i =>
                      i.FEEDLOT === yard &&
                      (!isNotEmpty(i.SHIPPING_DATE) ||
                        moment(i.SHIPPING_DATE, DateFormatServer.SHORT) >
                          moment().startOf('day')) // open lots
                  ) || []
                }
                averageUSDAScoreCompanyPast={
                  lotsWithLatestScan.averageUSDAScoreCompanyPast
                }
                averageDailyIncreaseInUSDAScoreCompanyOverall={
                  lotsWithLatestScan.averageDailyIncreaseInUSDAScoreCompanyOverall
                }
                loadLots={fetchData}
                loading={loading}
                auth={auth}
                snackbar={snackbar}
                org={org}
              />
            </DialogWrapperInfo>
          </Dialog>
          <Dialog
            open={openDialogLotDetails}
            onClose={() => setOpenDialogLotDetails(false)}
            isTransparent
          >
            <DialogWrapperInfo onClose={() => setOpenDialogLotDetails(false)}>
              {currentItem && (
                <LotDetails
                  lot={currentItem}
                  loadLots={fetchData}
                  averageDailyIncreaseInUSDAScoreCompanyOverall={
                    lotsWithLatestScan.averageDailyIncreaseInUSDAScoreCompanyOverall
                  }
                  auth={auth}
                  snackbar={snackbar}
                  org={org}
                />
              )}
            </DialogWrapperInfo>
          </Dialog>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3} xl={2} bgcolor="grey.200" mt={{ xs: 2, lg: 0 }}>
        <Stack bgcolor="grey.600" p={1}>
          <Typography color="grey.100">Upcoming Events</Typography>
        </Stack>
        <UpcomingEventList
          loading={loading}
          title="Lots to ship this week"
          lots={lotsShippingThisWeek}
          org={org}
        />
        <UpcomingEventList
          loading={loading}
          title="Lots to scan this week"
          lots={lotsScanningThisWeek}
          org={org}
        />
      </Grid>
    </Grid>
  );
};

export default Page;
