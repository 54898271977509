import { IconButton, Stack, Typography } from '@mui/material';
import { mean, std } from 'mathjs';
import { useState } from 'react';

import { Auth, LooseObject } from '../../../utils/Types';
import { Table } from '../../../components';
import { isNotEmpty } from '../../../utils/Helper';
import { East, West } from '@mui/icons-material';

const LotDetailsTables = ({
  animals,
  scans,
  loadingAnimals,
  loadingScans,
  averageDailyIncreaseInUSDAScoreCompanyOverall,
  auth,
}: {
  animals: LooseObject[];
  scans: LooseObject[];
  loadingAnimals: boolean;
  loadingScans: boolean;
  averageDailyIncreaseInUSDAScoreCompanyOverall: number | undefined;
  auth: Auth;
}) => {
  const [sessionOrder, setSessionOrder] = useState<'desc' | 'asc'>('desc');

  const columnBgcolors = [
    'rgba(40, 195, 95, 0.15)',
    'rgba(255, 96, 102, 0.15)',
    'rgba(255, 246, 200, 0.15)',
  ];

  const getColumnsEIDs = (order: 'desc' | 'asc') => {
    const columnsEIDs: any[] = [
      {
        columns: [
          { accessorKey: 'EID', header: 'EID', minSize: 150, maxSize: 200 },
        ],
        header: '',
        id: 'lot',
      },
      {
        columns:
          auth?.orgOwner === 'mishima'
            ? [
                {
                  accessorFn: (row: any) =>
                    row.scans ? row.scans[scans.length - 1]?.USDA : null,
                  header: 'USDA Score',
                  minSize: 100,
                  maxSize: 150,
                  id: 'mostRecentResults_USDA',
                  enableSorting: false,
                },
                {
                  accessorFn: (row: any) =>
                    row.scans
                      ? row.scans[scans.length - 1]?.MISHIMA_GRADE
                      : null,
                  header: 'Grade',
                  maxSize: 150,
                  id: 'mostRecentResults_Grade',
                  enableSorting: false,
                },
                {
                  accessorFn: (row: any) =>
                    row.scans ? row.scans[scans.length - 1]?.WEIGHT_LBS : null,
                  header: 'Weight',
                  maxSize: 130,
                  id: 'mostRecentResults_Weight',
                  enableSorting: false,
                },
              ]
            : [
                {
                  accessorFn: (row: any) =>
                    row.scans ? row.scans[scans.length - 1]?.USDA : null,
                  header: 'USDA Score',
                  maxSize: 150,
                  id: 'mostRecentResults_USDA',
                  enableSorting: false,
                },
                {
                  accessorFn: (row: any) =>
                    row.scans ? row.scans[scans.length - 1]?.WEIGHT_LBS : null,
                  header: 'Weight',
                  maxSize: 130,
                  id: 'mostRecentResults_Weight',
                  enableSorting: false,
                },
              ],
        header: 'Most Recent Results',
        id: 'mostRecentResults',
      },
      ...Array.from(Array(scans.length).keys()).map(i => ({
        columns:
          auth?.orgOwner === 'mishima'
            ? [
                {
                  accessorFn: (row: any) =>
                    row.scans?.[order === 'desc' ? scans.length - 1 - i : i]
                      ?.USDA,
                  header: 'USAD Score',
                  minSize: 100,
                  maxSize: 150,
                  id: `session${i}_Score`,
                  enableSorting: false,
                  muiTableBodyCellProps: {
                    sx: {
                      backgroundColor:
                        columnBgcolors[
                          (order === 'desc' ? scans.length - 1 - i : i) %
                            columnBgcolors.length
                        ],
                      borderWidth: 0,
                    },
                  },
                },
                {
                  accessorFn: (row: any) =>
                    row.scans?.[order === 'desc' ? scans.length - 1 - i : i]
                      ?.MISHIMA_GRADE,
                  header: 'Grade',
                  maxSize: 150,
                  id: `session${i}_Grade`,
                  enableSorting: false,
                  muiTableBodyCellProps: {
                    sx: {
                      backgroundColor:
                        columnBgcolors[
                          (order === 'desc' ? scans.length - 1 - i : i) %
                            columnBgcolors.length
                        ],
                      borderWidth: 0,
                    },
                  },
                },
                {
                  accessorFn: (row: any) =>
                    row.scans?.[order === 'desc' ? scans.length - 1 - i : i]
                      ?.WEIGHT_LBS,
                  header: 'Weight',
                  maxSize: 130,
                  id: `session${i}_Weight`,
                  enableSorting: false,
                  muiTableBodyCellProps: {
                    sx: {
                      backgroundColor:
                        columnBgcolors[
                          (order === 'desc' ? scans.length - 1 - i : i) %
                            columnBgcolors.length
                        ],
                      borderWidth: 0,
                    },
                  },
                },
              ]
            : [
                {
                  accessorFn: (row: any) =>
                    row.scans?.[order === 'desc' ? scans.length - 1 - i : i]
                      ?.USDA,
                  header: 'USAD Score',
                  maxSize: 150,
                  id: `session${i}_Score`,
                  enableSorting: false,
                  muiTableBodyCellProps: {
                    sx: {
                      backgroundColor:
                        columnBgcolors[
                          (order === 'desc' ? scans.length - 1 - i : i) %
                            columnBgcolors.length
                        ],
                      borderWidth: 0,
                    },
                  },
                },
                {
                  accessorFn: (row: any) =>
                    row.scans?.[order === 'desc' ? scans.length - 1 - i : i]
                      ?.WEIGHT_LBS,
                  header: 'Weight',
                  maxSize: 130,
                  id: `session${i}_Weight`,
                  enableSorting: false,
                  muiTableBodyCellProps: {
                    sx: {
                      backgroundColor:
                        columnBgcolors[
                          (order === 'desc' ? scans.length - 1 - i : i) %
                            columnBgcolors.length
                        ],
                      borderWidth: 0,
                    },
                  },
                },
              ],
        header: `Session ${
          scans[order === 'desc' ? scans.length - 1 - i : i].SEQUENCE_OF_SCAN
        }`,
        id: `session${i + 1}`,
      })),
    ];

    return columnsEIDs;
  };

  const getColumnsLotStats = (order: 'desc' | 'asc') => {
    const columnsLotStats: any[] = [
      {
        accessorKey: 'lostStats',
        header: 'Lot Stats',
        minSize: 200,
      },
      { accessorKey: 'current', header: 'Current', maxSize: 130 },
      ...scans.map((i, index) => ({
        accessorKey: `scan${
          order === 'desc' ? scans.length - 1 - index : index
        }`,
        header: `Scan ${
          scans[order === 'desc' ? scans.length - 1 - index : index]
            .SEQUENCE_OF_SCAN
        }`,
        id: `scan${order === 'desc' ? scans.length - 1 - index : index}`,
        enableSorting: false,
      })),
    ];
    return columnsLotStats;
  };

  const currentScan = scans.length > 0 ? scans[scans.length - 1] : {};
  const flappedScans: LooseObject = {
    HEADCOUNT: { current: currentScan.HEADCOUNT_SCANNED },
    ULTRA: { current: currentScan.COUNT_OF_ULTRA },
    '5_STAR': { current: currentScan['COUNT_OF_5_STAR'] },
    '4_STAR': { current: currentScan['COUNT_OF_4_STAR'] },
    CHOICE: { current: currentScan.COUNT_OF_CHOICE },
    WAGYU: { current: currentScan.COUNT_OF_WAGYU },
    USDA: { current: currentScan.AVG_USDA_SCORE },
  };
  scans.forEach((i, index) => {
    flappedScans.HEADCOUNT[`scan${index}`] = i.HEADCOUNT_SCANNED;
    flappedScans.ULTRA[`scan${index}`] = i.COUNT_OF_ULTRA;
    flappedScans['5_STAR'][`scan${index}`] = i['COUNT_OF_5_STAR'];
    flappedScans['4_STAR'][`scan${index}`] = i['COUNT_OF_4_STAR'];
    flappedScans.CHOICE[`scan${index}`] = i.COUNT_OF_CHOICE;
    flappedScans.WAGYU[`scan${index}`] = i.COUNT_OF_WAGYU;
    flappedScans.USDA[`scan${index}`] = i.AVG_USDA_SCORE;
  });

  const dailyIncreaseInUSDAArray = animals
    .filter(i => isNotEmpty(i.dailyIncreaseInUSDA))
    .map(i => i.dailyIncreaseInUSDA);

  const rowsLotStats = [
    { lostStats: 'Headcount Scanned', ...flappedScans.HEADCOUNT },
    { lostStats: 'Ultra', ...flappedScans.ULTRA },
    { lostStats: '5 Star', ...flappedScans['5_STAR'] },
    { lostStats: '4 Star', ...flappedScans['4_STAR'] },
    { lostStats: 'Choice', ...flappedScans.CHOICE },
    { lostStats: 'Wagyu', ...flappedScans.WAGYU },
    {
      lostStats: 'Avg. Daily Increase in USDA Score',
      current:
        dailyIncreaseInUSDAArray.length > 0
          ? Number.parseFloat(mean(...dailyIncreaseInUSDAArray).toFixed(2))
          : undefined,
    },
    {
      lostStats: 'Max Daily Increase in USDA Score',
      current:
        dailyIncreaseInUSDAArray.length > 0
          ? Math.max(...dailyIncreaseInUSDAArray)
          : undefined,
    },
    {
      lostStats: 'Min. Daily Increase in USDA Score',
      current:
        dailyIncreaseInUSDAArray.length > 0
          ? Math.min(...dailyIncreaseInUSDAArray)
          : undefined,
    },
    {
      lostStats: 'Average Standard Deviation',
      current:
        dailyIncreaseInUSDAArray.length > 0
          ? Number.parseFloat(std(...dailyIncreaseInUSDAArray).toFixed(4))
          : undefined,
    },
    // {
    //   lostStats: 'Company Avg. Daily Increase in USDA Score',
    //   current: averageDailyIncreaseInUSDAScoreCompanyOverall,
    // },
  ];

  const rowsLotStatsDemo = [
    { lostStats: 'Headcount Scanned', ...flappedScans.HEADCOUNT },
    { lostStats: 'USDA', ...flappedScans.USDA },
    {
      lostStats: 'Avg. Daily Increase in USDA Score',
      current:
        dailyIncreaseInUSDAArray.length > 0
          ? Number.parseFloat(mean(...dailyIncreaseInUSDAArray).toFixed(2))
          : undefined,
    },
    {
      lostStats: 'Max Daily Increase in USDA Score',
      current:
        dailyIncreaseInUSDAArray.length > 0
          ? Math.max(...dailyIncreaseInUSDAArray)
          : undefined,
    },
    {
      lostStats: 'Min. Daily Increase in USDA Score',
      current:
        dailyIncreaseInUSDAArray.length > 0
          ? Math.min(...dailyIncreaseInUSDAArray)
          : undefined,
    },
    {
      lostStats: 'Average Standard Deviation',
      current:
        dailyIncreaseInUSDAArray.length > 0
          ? Number.parseFloat(std(...dailyIncreaseInUSDAArray).toFixed(4))
          : undefined,
    },
    // {
    //   lostStats: 'Company Avg. Daily Increase in USDA Score',
    //   current: averageDailyIncreaseInUSDAScoreCompanyOverall,
    // },
  ];

  // rowsLotStats.splice(1, 0,  )

  const Tables = ({ order }: { order: 'desc' | 'asc' }) => (
    <Stack spacing={2} display={sessionOrder === order ? 'flex' : 'none'}>
      <Table
        renderTopToolbarCustomActions={() => (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h6">List Of Animals</Typography>
            <Stack direction="row">
              <IconButton
                size="small"
                onClick={() => setSessionOrder('desc')}
                disabled={sessionOrder === 'desc'}
              >
                <West />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => setSessionOrder('asc')}
                disabled={sessionOrder === 'asc'}
              >
                <East />
              </IconButton>
            </Stack>
          </Stack>
        )}
        columns={getColumnsEIDs(order)}
        data={animals}
        state={{ isLoading: loadingAnimals }}
        enableColumnActions={false}
        enableBottomToolbar={false}
        enablePagination={false}
        muiTableContainerProps={{ sx: { maxHeight: '400px' } }}
        initialState={{ density: 'compact' }}
        layoutMode="grid"
        muiTableHeadCellProps={{
          sx: () => ({
            backgroundColor: 'grey.100',
          }),
          align: 'center',
        }}
        muiTableBodyCellProps={{
          align: 'center',
        }}
      />
      <Table
        columns={getColumnsLotStats(order)}
        data={auth?.orgOwner === 'mishima' ? rowsLotStats : rowsLotStatsDemo}
        state={{ isLoading: loadingScans }}
        enableSorting={false}
        enableColumnActions={false}
        enableRowActions={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        layoutMode="grid"
        muiTableHeadCellProps={{
          sx: () => ({
            backgroundColor: 'grey.100',
          }),
        }}
        enablePagination={false}
        initialState={{ density: 'compact' }}
      />
    </Stack>
  );

  return (
    <Stack>
      <Tables order="desc" />
      <Tables order="asc" />
    </Stack>
  );
};

export default LotDetailsTables;
