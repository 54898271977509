import { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  DateFormatServer,
  FormInputCategory,
  LooseObject,
  Org,
  User,
} from '../../../utils/Types';
import InlineTextField from './InlineTextField';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import {
  getDateFormat,
  logout,
  postToServer,
  isNotEmpty,
} from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import TagButton from './TagButton';
import { CopyButton, Form, SmallButton } from '../../../components';
import TraitList from './TraitList';

type Props = {
  user?: User;
  item: LooseObject;
  org: Org;
  hideReportImageIssue?: boolean;
  onClick?: (item: any) => void;
  isImageUrlProvided?: boolean;
  minWidth?: string | number;
  maxWidth?: string | number;
  traitsOnSide?: boolean;
  shouldReloadListOfImages?: (v: boolean) => void;
};

const ImageItem = ({
  user,
  item,
  org,
  hideReportImageIssue,
  onClick,
  isImageUrlProvided,
  minWidth,
  maxWidth,
  traitsOnSide,
  shouldReloadListOfImages,
}: Props) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [itemWithUrl, setItemWithUrl] = useState<LooseObject>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElNotes, setAnchorElNotes] = useState<HTMLButtonElement | null>(
    null
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const auth = useAppSelector(selectAuth);

  // prevent mouse right click on image
  useEffect(() => {
    const handleContextmenu = (e: MouseEvent) => e.preventDefault();
    document.addEventListener('contextmenu', handleContextmenu);
    return () => document.removeEventListener('contextmenu', handleContextmenu);
  }, []);

  useEffect(() => {
    if (isImageUrlProvided || item.photo_url) {
      setItemWithUrl(item);
    }
  }, [item]);

  const onSubmitDataMaintainer = ({
    trait,
    glare,
    blur,
    exposure,
    wrong,
  }: {
    trait?: LooseObject;
    glare?: boolean;
    blur?: boolean;
    exposure?: boolean;
    wrong?: boolean;
  }) => {
    const params = {
      barcode: item.barcode,
      photoDate: item.photo_date,
      trait,
      isGlare: glare,
      isBlur: blur,
      isExposure: exposure,
      isWrong: wrong,
    };

    if (auth?.token && user?.isDataMaintainer) {
      postToServer({
        action: 'camera/UpdateTrait',
        params,
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success') {
            shouldReloadListOfImages?.(true);
          }
          snackbar.open(response.message);
        }
      });
    }
  };

  const onSubmitMsa = (msa: string) => {
    if (auth?.token && msa) {
      postToServer({
        action: 'camera/UpdateTraitMsa',
        params: {
          barcode: item.barcode,
          photoDate: item.photo_date,
          msa,
          msaOriginal: item.traits.find(
            (i: LooseObject) => i.key === 'msa_marbling_manual'
          )?.value,
        },
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success') {
            shouldReloadListOfImages?.(true);
          }
          snackbar.open(response.message);
        }
      });
    }
  };

  const onSubmit = ({
    blurry,
    badImage,
    notes,
  }: {
    blurry?: boolean;
    badImage?: boolean;
    notes?: string;
  }) => {
    const params = {
      barcode: item.barcode,
      photoDate: item.photo_date,
      isBlurry: blurry,
      isBadImage: badImage,
      notes,
    };

    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'camera/ReportPhotoIssue',
        params,
        token: auth.token,
      }).then(response => {
        setLoading(false);
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setAnchorEl(null);
          snackbar.open(response.message);
        }
        if (blurry !== undefined) {
          item.isBlurry = blurry;
        }
        if (badImage !== undefined) {
          item.isBadImage = badImage;
        }
        if (notes !== undefined) {
          item.notes = notes;
        }
      });
    }
  };

  const UpdateImageTraits = () => (
    <Stack direction="row" mb={1} spacing={1} useFlexGap flexWrap="wrap">
      <TagButton
        tag="glare"
        defaultState={item.isGlare}
        onSubmit={onSubmitDataMaintainer}
      />
      <TagButton
        tag="blur"
        defaultState={item.isBlur}
        onSubmit={onSubmitDataMaintainer}
      />
      <TagButton
        tag="exposure"
        defaultState={item.isExposure}
        onSubmit={onSubmitDataMaintainer}
      />
      <TagButton
        tag="wrong"
        defaultState={item.isWrong}
        onSubmit={onSubmitDataMaintainer}
      />
    </Stack>
  );

  const bgcolor = 'rgba(0, 0, 0, 0.5)';

  return (
    <Stack direction="row">
      <ImageListItem
        key={item.barcode}
        sx={{
          position: 'relative',
          height: '100% !important',
          bgcolor: 'rgba(0, 0, 0, 0.3)',
        }}
      >
        <Typography
          sx={{
            position: 'absolute',
            bgcolor,
            px: 1,
            py: 0.5,
            margin: 1,
            color: 'grey.100',
            borderRadius: 1,
          }}
        >
          {moment(item.photo_date, DateFormatServer.SHORT).format(
            getDateFormat(org, 'short')
          )}
        </Typography>
        {!itemWithUrl?.photo_url ? (
          <Stack
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor,
              maxWidth,
              minWidth: minWidth || 270,
              minHeight: minWidth ? `calc(${minWidth} * 16 / 9)` : 480,
              // minHeight: minWidth
              //   ? `min(calc(${minWidth} * 16 / 9), calc(87vh))`
              //   : 480,
              // maxHeight: `calc(87vh)`,
              height: '100%',
            }}
          >
            <Stack sx={{ width: '100%', flex: 1 }} />
            <Stack
              spacing={1}
              sx={{ width: '100%', flex: 2, alignItems: 'center' }}
            >
              <Stack width="100%" height={30} />
              <Typography textAlign="center" color="grey.100">
                No image available.
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <img
            onDragStart={(e: any) => e.preventDefault()} // prevent image drag
            src={itemWithUrl?.photo_url}
            alt={item.barcode}
            loading="lazy"
            onClick={() => onClick?.(itemWithUrl)}
            style={{
              minWidth: minWidth || 270,
              minHeight: minWidth ? `calc(${minWidth} * 16 / 9)` : 480,
              // minHeight: minWidth
              //   ? `min(calc(${minWidth} * 16 / 9), calc(87vh))`
              //   : 480,
              // maxHeight: `calc(87vh)`,
              backgroundColor: bgcolor,
            }}
          />
        )}
        <ImageListItemBar
          title={
            <Stack>
              {user?.isDataMaintainer ? (
                <UpdateImageTraits />
              ) : (
                !hideReportImageIssue && (
                  <Stack direction="row" justifyContent="space-between">
                    <Stack
                      direction="row"
                      mb={1}
                      spacing={1}
                      useFlexGap
                      flexWrap="wrap"
                    >
                      <TagButton
                        tag="blurry"
                        defaultState={item.isBlurry}
                        onSubmit={onSubmit}
                      />
                      <TagButton
                        tag="badImage"
                        defaultState={item.isBadImage}
                        onSubmit={onSubmit}
                      />
                      <Stack>
                        <Tooltip title="Add notes">
                          <IconButton
                            sx={{ height: 20, color: 'grey.100' }}
                            onClick={(
                              event: React.MouseEvent<HTMLButtonElement>
                            ) => {
                              setAnchorEl(event.currentTarget);
                            }}
                          >
                            <MoreHorizOutlined />
                          </IconButton>
                        </Tooltip>
                        <Popover
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={() => setAnchorEl(null)}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <Stack p={2}>
                            <Form
                              loading={loading}
                              onSubmit={data => onSubmit(data)}
                              buttonText="Submit"
                              inputs={[
                                {
                                  name: 'notes',
                                  label: 'Notes',
                                  category: FormInputCategory.TEXT_INPUT,
                                  placeholder: 'Please add notes ...',
                                  multiline: true,
                                  defaultValue: item.notes,
                                },
                              ]}
                              buttonFullWidth={false}
                              buttonSize="small"
                            />
                          </Stack>
                        </Popover>
                      </Stack>
                    </Stack>
                    {isNotEmpty(item.notes) && (
                      <Stack>
                        <SmallButton
                          title="View notes"
                          variant="text"
                          sx={{
                            px: 0.2,
                            py: 0,
                            fontSize: 10,
                            textTransform: 'none',
                            height: 20,
                            color: 'grey.100',
                          }}
                          onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                          ) => {
                            setAnchorElNotes(event.currentTarget);
                          }}
                        />
                        <Popover
                          open={Boolean(anchorElNotes)}
                          anchorEl={anchorElNotes}
                          onClose={() => setAnchorElNotes(null)}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <Stack p={2}>
                            <Typography>{item.notes}</Typography>
                          </Stack>
                        </Popover>
                      </Stack>
                    )}
                  </Stack>
                )
              )}
              {!traitsOnSide && (
                <TraitList
                  item={item}
                  user={user}
                  org={org}
                  onSubmitDataMaintainer={onSubmitDataMaintainer}
                  onSubmitMsa={onSubmitMsa}
                />
              )}
            </Stack>
          }
          subtitle={
            item.barcode?.length > 6 ? (
              <Stack direction="row">
                <Typography noWrap fontSize={15}>
                  {item.barcode.substring(0, item.barcode.length - 5)}
                </Typography>
                <Typography fontSize={15}>
                  {item.barcode.substring(item.barcode.length - 5)}
                </Typography>
              </Stack>
            ) : (
              item.barcode
            )
          }
          actionIcon={
            <CopyButton
              text={item.barcode}
              sx={{ color: 'rgba(255, 255, 255, 0.50)', mb: 0.5 }}
            />
          }
          sx={{ alignItems: 'flex-end' }}
        />
      </ImageListItem>
      {traitsOnSide && (
        <Stack bgcolor="grey.900" p={2} justifyContent="center" minWidth={150}>
          {item.traits?.map((i: any) => (
            <Stack
              direction="row"
              key={i.key}
              spacing={1}
              alignItems="center"
              color="grey.100"
            >
              <Typography variant="caption" fontWeight="bold">
                {i.label}:
              </Typography>
              {user?.isDataMaintainer ? (
                <InlineTextField
                  item={item}
                  traitKey={i.key}
                  onSubmit={onSubmitDataMaintainer}
                />
              ) : (
                <Typography variant="caption">{i.value}</Typography>
              )}
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default ImageItem;
